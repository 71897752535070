/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import ogImage from "../assets/images/Thumbnail.png";

function SEO({ description, lang, meta, title }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  );

  const metaDescription = description || site.siteMetadata.description;

  return (
    <>
      <title>{title}</title>
      <meta name="description" content={metaDescription}></meta>
      <meta property="og:title" content={title}></meta>
      <meta property="og:description" content={metaDescription}></meta>
      <meta property="og:type" content="website"></meta>
      <meta
        property="og:image"
        content={`https://orangejuice.asia/${ogImage}`}
      ></meta>
    </>
  );
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
  title: "Orange Juice Asia",
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
};

export default SEO;
